<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"></div>
				</div>
			</div>
			<div class="menu">
				<div class="menu-box">
					<div class="menu-list" v-for="(item,index) in cates" :key="index" @click="selectCate(item.id,2)"
						v-if="index<5">
						{{item.name}}
					</div>
				</div>
			</div>
		</div>
		<div class="index-content">
			<div class="banner">
				<div class="menu-content" @mouseleave="handleMouseLeave">
					<div class="menu-box">
						<div @mouseenter="handleMouseEnter" :data-id="index" class="menu-list"
							v-for="(item,index) in cates" :key="index">{{item.name}}</div>
					</div>
					<div class="menu-child-box" v-if="cateShow">
						<span @click="selectCate(item.id,1)"
							v-for="(item,index) in cates[cateIndex].children">{{item.name}}</span>
					</div>
				</div>
				<el-carousel height="400px" style="cursor: pointer;">
					<el-carousel-item v-for="(item,index) in banners" :key="index">
						<img class="banner-list" :src="item.banner" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<ul class="item-nav">
				<li class="item-list" v-for="(item,index) in list" :key="index"
					@click="toUrl('/item/detail?id='+item.id)">
					<div class="item-img">
						<img src="../../assets/drug.jpg" v-if="item.itemType==5" :alt="item.name" />
						<img :src="item.adPic" v-if="item.itemType!=5" :alt="item.name" />
					</div>
					<div class="item-name">
						<span v-if="item.itemType==5">处方药</span>{{item.name}}
					</div>
					<div class="item-price">
						<div class="mod-price">
							<i>￥</i>
							<span>{{item.isPrice}}</span>
							<span class="io-price" v-if="item.ioPrice>item.isPrice">￥{{item.ioPrice}}</span>
						</div>
					</div>
					<div class="shop-name">
						<p>{{item.shopName}}</p>
					</div>
				</li>
			</ul>
			<div class="more" @click="toUrl('/item/search')">查看更多>></div>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		getCookie,
		delCookie
	} from '@/utils/auth'
	import {
		itemCate,
		itemList
	} from '@/api/item/item'
	import {
		bannerList
	} from '@/api/index/index'
	export default {
		data() {
			return {
				cates: [],
				banners: [],
				cateIndex: 0,
				cateShow: false,
				params: {
					data: {
						isSell: 1,
						cateIds: [],
						itemTypes: [5, 6]
					},
					page: 1,
					pageSize: 12
				},
				list: [],
				more: true,
				loading: false,
				name: ''
			};
		},
		created() {
			this.getCate()
			this.getBanner()
			this.getItemList()
		},
		methods: {
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			view() {
				var url =
				'https://aksoapplet.oss-cn-hangzhou.aliyuncs.com/applent/contract/serviceAgreement.docx'; //要预览文件的访问地址
				window.open('http://43.138.109.233:8012/onlinePreview?url=' + encodeURIComponent(url));
			},
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			selectCate(id, type) {
				this.$router.push({
					path: '/item/search?cate=' + id + '&type=' + type
				})
			},
			getCate() {
				itemCate({}).then(res => {
					this.cates = res.data
				})
			},
			getBanner() {
				bannerList({}).then(res => {
					this.banners = res.data
				})
			},
			handleMouseEnter(e) {
				var index = e.target.dataset.id
				this.cateIndex = index
				this.cateShow = true
			},
			handleMouseLeave(e) {
				this.cateShow = false
			},
			getItemList() {
				if (this.loading) {
					return
				}
				if (!this.more) {
					return
				}
				this.loading = true
				itemList(this.params).then(res => {
					this.list = this.list.concat(res.data.data)
					this.loading = false
					if (this.params.page == res.data.pages || res.data.pages == 0) {
						this.more = false
					} else {
						this.params.page = this.params.page + 1
						this.more = true
					}
				})
			}
		},
	};
</script>

<style lang='scss'>
	.index-content {
		min-width: 1200px;

		.banner {
			width: 80%;
			margin-left: 10%;
			height: 400px;
			background: #999;

			.banner-list {
				width: 100%;
				height: 400px;
			}

			.menu-content {
				width: 50%;
				position: absolute;
				z-index: 999;
				height: 400px;
				cursor: pointer;

				.menu-box {
					width: 240px;
					background-color: #fefefe;
					height: 400px;

					.menu-list {
						height: 25px;
						line-height: 25px;
						padding-left: 18px;
						font-size: 14px;
						color: #333;
					}

					.menu-list:hover {
						color: #c81623;
						background-color: #d9d9d9;
					}
				}

				.menu-child-box {
					height: 400px;
					position: absolute;
					left: 191px;
					top: 0;
					width: 500px;
					border: 1px solid #f7f7f7;
					background-color: #fff;
					transition: top .25s ease;
					box-shadow: 2px 0 5px rgba(0, 0, 0, .3);

					span {
						height: 25px;
						line-height: 25px;
						padding-left: 10px;
						padding-right: 10px;
						padding-top: 5px;
						padding-bottom: 5px;
						font-size: 14px;
						color: #333;
						margin-top: 10px;
					}

					span:hover {
						color: #c81623;
						background-color: #d9d9d9;
					}
				}
			}
		}

		.item-nav {
			margin-left: 10%;
			width: 80%;
			background-color: #f4f4f4;


			.item-list {
				cursor: pointer;
				background: #fff;
				display: inline-table;
				width: 230px;
				height: 322px;
				margin: 0 10px 10px;
				margin-top: 20px;
				border-radius: 2px;

				.item-img {
					display: block;
					transition: background .2s linear, opacity .2s linear !important;
					background: transparent;
					position: relative;
					overflow: hidden;
					width: 150px;
					height: 150px;
					margin: 30px auto 40px;
				}

				.item-img:hover {
					width: 160px;
					height: 160px;
					margin: 20px auto 40px;
				}

				.item-img>img {
					display: block;
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					margin: 0 auto;
				}

				.item-name {
					padding: 0 20px;
					height: 48px;
					font-size: 14px;
					line-height: 24px;
					text-align: left;
					color: #333;
					-webkit-transition: color .2s ease;
					transition: color .2s ease;
					word-break: break-all;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

					span {
						padding-left: 5px;
						padding-right: 5px;
						color: #fff;
						background-color: #e3393c;
						font-size: 12px;
						border-radius: 5px;
						margin-right: 5px;
					}
				}

				.item-name:hover {
					font-weight: bold;
				}

				.item-price {
					margin-top: 10px;
					padding-left: 20px;

					.mod-price {
						float: left;
						width: 75px;
						text-align: left;
						display: inline-block;
						font-size: 16px;
						line-height: 18px;
						height: 18px;
						color: #e1251b;

						i {
							vertical-align: middle;
							font-size: 12px;
							font-weight: 700;
						}

						span {
							font-size: 20px;
							font-weight: 700;
						}

						.io-price {
							font-size: 16px;
							color: #666;
							text-decoration: line-through;
							font-weight: normal;
							padding-left: 10px;
						}
					}
				}

				.shop-name {
					clear: both;
					padding-left: 20px;
					padding-top: 10px;
				}
			}

			.item-list:hover {
				transition: top .25s ease;
				box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
			}
		}

		.more {
			line-height: 40px;
			cursor: pointer;
			color: #777;
			text-align: center;
			font-size: 18px;
		}
	}
</style>